<template>
  <v-list-item
    v-bind="$attrs"
    v-on="{ ...$listeners }"
    color="primary"
    :value="value"
  >
    <v-list-item-content>
      <v-list-item-title>
        {{ value.code }}
      </v-list-item-title>
    </v-list-item-content>
    <v-list-item-action v-if="action"
      ><v-icon>{{ action }}</v-icon></v-list-item-action
    >
  </v-list-item>
</template>
<script>
export default {
  name: 'SchoolClass',
  props: {
    action: null,
    value: null,
  },
};
</script>
